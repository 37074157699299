import { Controller } from "stimulus";

export default class extends Controller {
  element_ids = {
    price_unit:
      "corporate_leasing_offer_pricing_maintenance_budget_maintenance_price_unit", //Huoltobudjetti
    km: "corporate_leasing_offer_pricing_maintenance_budget_maintenance_budget_km", // Sopimuksen kilometrit (km)
    total_maintenance:
      "corporate_leasing_offer_pricing_maintenance_budget_maintenance_total_budget", // Huoltohinta yhteensä (€)
    storage_price_unit:
      "corporate_leasing_offer_pricing_maintenance_budget_tire_storage_price_unit", // Rengassäilytys €/kk
    period:
      "corporate_leasing_offer_pricing_maintenance_budget_tire_storage_period", // Sopimusaika (kk)
    total_tire_storage:
      "corporate_leasing_offer_pricing_maintenance_budget_tire_storage_total_budget", // Rengassäilytys yhteensä (€)
    tire_renewal:
      "corporate_leasing_offer_pricing_maintenance_budget_tires_renewal_budget", // Renkaiden uusinta (€)
    monthly_price: "corporate_leasing_offer_pricing_monthly_rent", // Kuukausivuokra
    monthly_service:
      "corporate_leasing_offer_pricing_maintenance_budget_monthly_maintenance", // Huolot €/kk
    overall_total_maintenance_budget:
      "corporate_leasing_offer_pricing_maintenance_budget_total_maintenance_budget", // Huoltojenhinta yhteensä €
    total_monthly_price:
      "corporate_leasing_offer_pricing_maintenance_budget_total_monthly_rent",
    calculate_manually:
      "corporate_leasing_offer_pricing_maintenance_budget_manual_calculation",
    substitute_car: 
      "corporate_leasing_offer_pricing_maintenance_budget_substitute_car_price",
  };

  element_values = {};

  connect() {
    setTimeout(() => {
      this.setElementValues();
      let summaryFields = {
        monthly_maintenance_summary: this.element_values.monthly_service,
        monthly_rent_summary: this.element_values.monthly_price,
      };
      this.updateSummaryFields(summaryFields);
      if (location.pathname.split("/").includes("new")) {
        this.calculateTotalMaintenance();
      }
    }, 100);
  }

  calculateTotalPrice(e) {
    let element = e.target;
    let id = element.id;
    let key = this.findElementKeyByValue(id);
    this.element_values[key] = element.value;
    if(key === "period" && !element.value){
      let period = document.getElementById("corporate_leasing_offer_agreement_agreement_period");
      if(period){
        this.element_values[key] = period.value;
      }
      // console.log("period is null", element.value);
    }
    if (element.type === "checkbox" && key === "calculate_manually") {
      this.element_values[key] = element.checked;
    }
    this.calculateTotalMaintenance(key);
  }

  setElementValues() {
    Object.keys(this.element_ids).forEach((key) => {
      if (key === "calculate_manually") {
        this.element_values[key] = document.getElementById(
          this.element_ids[key]
        ).checked;
      } else {
        this.element_values[key] = document.getElementById(
          this.element_ids[key]
        ).value;
      }
    });
  }

  findElementKeyByValue(value) {
    return Object.keys(this.element_ids).find(
      (key) => this.element_ids[key] === value
    );
  }

  //{corporate_leasing_offer_pricing_maintenance_price_unit: '0.025', corporate_leasing_offer_pricing_maintenance_budget_km: '60000', corporate_leasing_offer_pricing_tire_storage_price_unit: '15.50', corporate_leasing_offer_pricing_tire_storage_period: '36', corporate_leasing_offer_pricing_tires_renewal_budget: '1000'}
  calculateTotalMaintenance(key) {
    try {
      let {
        price_unit,
        km,
        total_maintenance,
        storage_price_unit,
        period,
        tire_renewal,
        monthly_price,
        calculate_manually,
        substitute_car,
      } = this.element_values;

      const periodElement = document.getElementById(this.element_ids["period"]);
      if (periodElement && !periodElement.value) {
        periodElement.classList.add("border-danger");
      } else {
        periodElement.classList.remove("border-danger");
      }

      let calc_total_maintainer =
        this.setDefaultValues(price_unit) * this.setDefaultValues(km);
      if (calculate_manually) calc_total_maintainer = total_maintenance;
      let calc_tire_storage =
        this.setDefaultValues(storage_price_unit) *
        this.setDefaultValues(period);
      let calc_overall_total_maintenance_budget =
        this.setDefaultValues(tire_renewal) +
        this.setDefaultValues(calc_tire_storage) +
        this.setDefaultValues(calc_total_maintainer) +
        this.setDefaultValues(substitute_car);
      let calc_monthly_service = (
        calc_overall_total_maintenance_budget / this.setDefaultValues(period)
      ).toFixed(2);

      let calc_total_monthly_price = (
        this.setDefaultValues(monthly_price) +
        this.setDefaultValues(calc_monthly_service)
      ).toFixed(2);
      let updatedFields = {
        total_maintenance: calc_total_maintainer,
        monthly_service: calc_monthly_service,
        overall_total_maintenance_budget: calc_overall_total_maintenance_budget,
        total_monthly_price: calc_total_monthly_price,
        total_tire_storage: calc_tire_storage,
      };
      Object.keys(updatedFields).forEach((key) => {
        let id = this.element_ids[key];
        let element = document.getElementById(id);
        element.value = updatedFields[key];
      });
      let summaryFields = {
        monthly_maintenance_summary: calc_monthly_service,
        monthly_rent_summary: monthly_price,
      };
      this.updateSummaryFields(summaryFields);
    } catch (e) {
      console.log("Error calculateTotalMaintenance:", e);
    }
  }

  updateSummaryFields(summaryFields) {
    Object.keys(summaryFields).forEach((key) => {
      let element = document.getElementById(key);
      element.textContent = summaryFields[key];
    });
  }

  setDefaultValues(amount) {
    return amount ? parseFloat(amount) : 0;
  }

  checkVehicleCondition() {
    let vehicle_condition = document.getElementById(
      "corporate_leasing_offer_vehicle_info_vehicle_condition"
    );
    let car_tax = document.getElementById(
      "corporate_leasing_offer_vehicle_info_vehicle_tax"
    );
    let car_km = document.getElementById(
      "corporate_leasing_offer_vehicle_info_driven_km"
    );

    car_tax.required = false;
    car_km.required = false;

    if (vehicle_condition.value === "uusi") {
      car_tax.required = true;
    } else {
      car_km.required = true;
    }
  }

  totalVehiclePrice() {
    let total_price = document.getElementById(
      "corporate_leasing_offer_pricing_total_price"
    );
    let total_price_copy = document.getElementById("total_price_copy");

    let calculationFields = [
      "corporate_leasing_offer_pricing_purchase_price",
      "corporate_leasing_offer_pricing_accessories_price",
      "corporate_leasing_offer_pricing_profit",
    ];
    let total = calculationFields.reduce((total, field) => {
      let value = document.getElementById(field).value || "0";
      return total + parseFloat(value);
    }, 0);

    total_price.value = total;
    total_price_copy.value = total;
  }

  originalStoragePriceUnit = undefined;
  originalTireRenewal = undefined;
  originalSubstituteCarPrice = undefined;

  resetSectionBudget(e) {
    let element = e.target;
    let value = element.value;
    let codeName = element.dataset.codename;
    let recalculate = false;

    if (codeName === "maintenance_budget_km" && value === "Ei") {
      let id =
        "corporate_leasing_offer_pricing_maintenance_budget_maintenance_total_budget";
      let key = this.findElementKeyByValue(id);

      this.element_values["price_unit"] = 0;
      let { price_unit } = this.element_ids;
      document.getElementById(price_unit).value = 0;
      recalculate = true;
    }

    if (codeName === "storage_price_unit") {
      this.handleResetSectionBudget("storage_price_unit", "originalStoragePriceUnit", value);
      recalculate = true;
    }

    if (codeName === "tire_renewal") {
      this.handleResetSectionBudget("tire_renewal", "originalTireRenewal", value);
      recalculate = true;
    }
    
    if (codeName === "substitute_car" ) {
      this.handleResetSectionBudget("substitute_car", "originalSubstituteCarPrice", value);
      recalculate = true;
    }

    if (recalculate) {
      this.calculateTotalMaintenance();
    }
  }

  handleResetSectionBudget(elementKey, originalValueKey, value) {
    const elementId = this.element_ids[elementKey];
    const valueElement = document.getElementById(elementId);
    if (valueElement.value > 0) {
      this[originalValueKey] = valueElement.value;
    }
    if (value === "Ei") {
      valueElement.value = 0;
    } else {
      valueElement.value = this[originalValueKey];
    }
    this.element_values[elementKey] = valueElement.value;
  }
}
